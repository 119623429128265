import React, { FC, memo } from 'react';
// styles
import styles from './send-message.module.css';
// components
import Top from 'components/feedback/top';
// helpers
import { useHubspotContext } from 'hooks/useHubspotContext';
import { ISendMessageForm, prepareDataForSendStrapi } from './helpers';
import { handleGaEvent } from 'utils/helpers';
import HubspotForm from './hubspot-embedded-form';
// api
import { api } from 'utils/api';

interface IProps {
  className?: string;
  onClick: () => void;
}

const SendMessage: FC<IProps> = ({ className, onClick }) => {
  const context = useHubspotContext();
  const onHubspotFormSubmit = async (data: ISendMessageForm) => {
    try {
      const resp = await api.strapi.createContactRequest(
        await prepareDataForSendStrapi({
          ...data,
          ...context,
        })
      );
      const userId = String(resp.data.id);
      handleGaEvent({ event: 'formSubmission', formType: 'Request contact', userId: userId });
      // return userId;
    } catch (err) {
      console.log(err);
      // return '';
    }
  };

  return (
    <div className={className}>
      <Top title="Send a message" button="Book a call" onClick={onClick} />
      <div className={styles.wrapper}>
        <h3 className={styles.formLabel}>Write to Us</h3>

        <HubspotForm sendDataToStrapi={onHubspotFormSubmit} />
      </div>
    </div>
  );
};

export default memo(SendMessage);
